@import "../../helpers/variable.scss";

.header_container {
	z-index: 10;
	background: #f9f9f9;
	width: 100%;
	left: 0;
	top: 0;
	box-shadow: 0 3px 12px rgba(0, 0, 0, 0.07);
	position: sticky;
}
.header_logo_container {
	max-height: 100%;
	max-width: none;
	background-image: url("../../img/zPOS_logo_RGB_color-cropped.svg");
	height: $headerHeight;
	background-repeat: no-repeat;
	background-size: contain;
	margin: 10px 0px 5px 0px;
	grid-area: header_logo;
}
.container {
	// display: flex !important;
	// justify-content: space-between;
	display: grid !important;
    grid-template-columns: 1fr auto auto;
	grid-template-areas: "header_logo header_tabs header_button"
                         "header_user_info header_user_info header_user_info";
}
.container_skeleton {
	height: $headerHeight;
	display: flex !important;
	align-items: center;
	justify-content: space-between;
}
.header_tabs_container {
	display: flex;
	position: relative;
	justify-self: flex-end;
	grid-area: header_tabs;
}
.header_tab {
	cursor: pointer;
	.header_tab_all {
		text-align: center;
		align-items: center;
		display: flex;
		text-decoration: none;
		color: $headerInactive;
		height: 100%;
		font-size: 14px;
		text-transform: uppercase;
		transition-property: color;
		transition: color 0.5s;
		position: relative;
		&:hover {
			color: #000;
		}
	}
	.header_tab_all_last {
		text-align: center;
		align-items: center;
		display: flex;
		text-decoration: none;
		height: 100%;
		font-size: 14px;
		text-transform: uppercase;
		transition-property: color;
		transition: color 0.5s;
		position: relative;
		color: #ca2a38;
	}
	&:first-child {
		margin-left: 0 !important;
	}
	&:nth-child(n-1) {
		margin-left: 20px;
	}
}
.header_button_burger {
	display: none;
	justify-content: center;
	margin: 20px 0;
}
.header_button_settings {
	position: relative;
	display: flex;
	align-items: center;
	margin-left: 10px;
	height: 100%;
	color:inherit;
	grid-area: header_button;
}
.header_user_info {
	display: flex;
	justify-content: space-between;
	margin: 0px 0px 2px 0px;
	color: $headerInactive;	
	.header_user_info_org, .header_user_info_user {
		font-size: small;
	}	
	grid-area: header_user_info;
}
.header_tab_active {
	&::before {
		content: "";
		z-index: 1;
		position: absolute;
		width: 100%;
		top: 0;
		border-top: 3px solid #ca2a38 !important;
	}
	color: #000 !important;
	// box-sizing: border-box;
}
.header_line {
	height: 3px;
	background: #f26572;
	position: absolute;
	top: 0;
	transition: left 0.4s, width 0.4s;
}

.menu_wrapper {
	outline: 0;
	outline-offset: 0;
	margin-top: -1px;
	cursor: pointer;
}
.menu_conatiner_wrapper {
	display: none;
}
@media screen and (max-width: 1130px) {
	.menu_conatiner_wrapper {
		display: block;
	}
	.header_line {
		display: none;
	}
	.header_button_burger {
		display: flex;
	}
	.header_tab_active {
		&::before {
			display: none;
		}
	}
	.header_tabs_container {
		display: none !important;
	}
	[class*="bm-overlay"] {
		top: 80px;
	}
	[class*="bm-menu-wrap"] {
		top: 80px;
		overflow: hidden;
		outline: none;
		background: #f9f9f9;
		padding-left: 20px;
		[class*="bm-cross-button"] {
			display: none;
		}
		* {
			outline: none;
		}
	}
	.header_tab {
		&:nth-child(n) {
			margin-left: 0;
			padding-top: 18px;
		}
		&:nth-last-child(2) {
			margin-bottom: 20px;
		}
	}
	.header_display_none {
		display: none;
	}
}
@media screen and (max-width: 500px) {
	[class*="bm-menu-wrap"] {
		width: 60% !important;
	}
}
