@import "../../helpers/variable.scss";

.modal_content_wrapper {
	padding: 20px;
    *background: #f6f6f6;
    overflow-x: hidden;
    min-width: 320px;
    min-height: 125px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
	justify-content: center;
}
.modal_button_block {
	display: flex;
	justify-content: center;
	margin-bottom: 20px;
	flex-flow: wrap;
}
.modal_button_block_two {
	justify-content: space-around;
	// flex-flow: wrap-reverse;
	button {
		margin: 5px;
	}
}
.modal_wrapper {
	position: relative;
	overflow-x: hidden;
	& *{
		overflow-x: hidden !important;
	}
}
.close {
	position: absolute;
	right: 3px;
	top: 3px;
	width: 20px;
	height: 20px;
	opacity: 0.9;
	cursor: pointer;
	transition: all 0.6s ease;
}
.close:hover {
	transform: rotate(90deg);
}
.close:before,
.close:after {
	position: absolute;
	left: 8px;
	content: " ";
	height: 20px;
	width: 4px;
	background-color: #ff4f4f;
}
.close:before {
	transform: rotate(45deg);
}
.close:after {
	transform: rotate(-45deg);
}

.modal_loading {
	color: $red !important;
	height: $loaderSize !important;
	width: $loaderSize !important;
	left: 40%;
    position: relative;
    top: 35px;
}

@media screen and (max-width: 460px) {
	.modal_content_wrapper {
		min-width: auto;
	}
}
