@import "../../helpers/variable.scss";

.button_default {
    background: $red !important;
    color: white !important;
    border: 1px solid $red !important;
    &:hover{
        background: #ca2a38c4 !important;
        border: 1px solid $red !important;
    }
}
.button_blue {
	background-color: $blue !important;
	color: #FFFFFF !important;
    border: 1px solid $blue !important;
    &:hover{
        background: #169BD5c4 !important;
        border: 1px solid $blue !important;
    }
}
.button_outlined{
    background: white !important;
    color: $red !important;
    border: 1px solid $red !important;
    &:hover{
        background: white !important;
        color: #ca2a38c4 !important;
        border: 1px solid #ca2a38c4 !important;
    }
}