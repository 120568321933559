$headerHeight: 35px;
$footerHeight: 70px;
$headerInactive: #6d6d6d;
$spaceBeetween: 10px;
$tableRowHeight: 40px;
$red: #ca2a38;
$green: #03B615;
$yellow: #FFFF00;
$blue: #169BD5;
$loaderSize: 55px;

.radio_button {
	& [class*="Mui-checked"] svg {
		color: $red !important;
	}
}

[class*="MuiSwitch-thumb"] {
	color: $red !important;
}
[class*="Mui-checked"] + [class*="MuiSwitch-track"]{
	background-color: $red !important;
}
[class*="Mui-disabled"] {
	& [class*="MuiSwitch-thumb"]{
		opacity: 0.6;
	}
}


//loader
.loading_container {
	position: relative;
}
.global_loading {	
	color: $red !important;
	position: absolute;
	top: 50%;
	left: 50%;
	height: $loaderSize !important;
	width: $loaderSize !important;
	margin-top: -$loaderSize;
	margin-left: -$loaderSize;	
}

//common select style
.hidden {
	display: none;
}
.month_picker {
	min-width: 135px;
}
.style_select {
	fieldset {
		outline: none;
	}
	[class*="MuiSelect-root MuiSelect-select MuiSelect-selectMenu MuiSelect-outlined MuiInputBase-input MuiOutlinedInput-input"] {
		padding: 9px 14px;
	}
}
[class*="MuiInputBase-root MuiOutlinedInput-root prepareOrderPay_style_select__1s89z Mui-focused Mui-focused MuiInputBase-formControl"] {
	[class*="PrivateNotchedOutline-root-96 MuiOutlinedInput-notchedOutline"] {
		border: 1px solid #0000003b !important;
	}
}
[class*=".MuiButton-root"]{
	padding: 6px 17px;
}
//common styles block
.filter_tool_tip {
	margin-left: $spaceBeetween !important;
	padding: 0 !important;
}
.button_container {
	&:not(:first-child){
		margin: 20px 0;
	}	
	display: flex;
	justify-content: space-between;
	align-items: center;
	button:not(:last-child) {
		margin-right: $spaceBeetween;
	}
}
.item_container_row {
	display: flex;
	align-items: center;
	& > *:not(:last-child) {
		margin-right: $spaceBeetween;
	}
}
.container_y_wrapper_left {
	display: flex;
	flex-direction: column;
	justify-content: start;
}
.container_h_wrapper_left {
	display: flex;
	justify-content: start;
}
.container_h_wrapper_right {
	display: flex;
	justify-content: end;
}
.container_h_wrapper_center {
	display: flex;
	justify-content: center;
}
.container_h_wrapper_beetwen {
	display: flex;
	justify-content: space-between;
}
.text_container {
	padding-bottom: $spaceBeetween;
}
.no_margin {
	margin: 0 !important;
}
.no_padding {
	padding: 0 !important;
}
.text_center {
	text-align: center !important;
}
.text_right {
	text-align: right !important;
}
.text_left {
	text-align: left !important;
}
.justify_end {
	justify-content: end;
}
.service_info_row:not(:last-child) {
	padding-bottom: $spaceBeetween;
}
.warning_text {
	color: $red;
}
//common tables
.table_description_container {
	padding-bottom: $spaceBeetween;
}
.table_container {
	min-width: 750px;
	position: relative;
	padding: $spaceBeetween;
	margin-bottom: $spaceBeetween * 2;
	td {
		height: $tableRowHeight;
		border: 1px solid rgb(224, 224, 224);
		text-align: left;
		vertical-align: top;
		padding: $spaceBeetween;
	}
	& th {
		padding: $spaceBeetween;
		font-family: 'default';
		text-align: center;
	}
}
.table_wrapper {
	position: relative;
}
.subtable_header {
	& th {
		padding: 0;
		text-align: center;
		line-height: 20px;		
	}
}

.checkbox_th {
	width:5%;
	max-width: 5%;
	min-width: 5%;
}

[class*="MuiButtonBase-root MuiButton-root"]{
	min-height: 33px;
}

.container_margin_right {
	& div:not(:last-child){
		margin-right: $spaceBeetween;
	}
}

@media screen and (max-width: 810px) {
	.table_container {
		min-width: inherit;
	}
	.table_wrapper {
		overflow-x: scroll !important;
	}
	.collapse_sigle_table {
		width: 28px;
	}
	[class*="details_container_h_wrapper"] {
		& div:not(:last-child){
			margin-right: $spaceBeetween;
		}
	}
}

@mixin cursorPointer {
	cursor: pointer;
}
@mixin colorPicker {
	.table_color_def {
		@include cursorPointer;
		&:hover {
			background: rgb(247, 247, 247);
		}
	}
	.table_color_new {
		background: #ffc0c044;
		&:hover {
			background: #ffa8a8;
		}
		@include cursorPointer;
	}
	.table_color_warn {
		background: #ffe5853b;
		&:hover {
			background: #ffe58562;
		}
		@include cursorPointer;
	}
	.table_color_off {
		background: #eaeaea;
		&:hover {
			background: #dadada;
		}
		@include cursorPointer;
	}
	.table_color_pre {
		background: #e8ffe29a;
		&:hover {
			background: #c7ffb99a;
		}
		@include cursorPointer;
	}
}

@font-face {
	font-family: "Open Sans";
	src: url("../font/OpenSans-Bold.ttf");
}
@font-face {
	font-family: "default";
	src: url("../font/OpenSans-SemiBold.ttf");
}
@font-face {
	font-family: "default_header";
	src: url("../font/OpenSans-Light.ttf");
}